import React from "react";

import Grid from "@mui/material/Grid";
import PageTemplate, { MaxGridContainer } from "components/PageTemplate";
import ProjectPage from "components/projectPage";
import { ShowMdGrid } from "./home";

const Embroidery = () => (
  <PageTemplate>
    <ProjectPage
      title="What's the Stitch"
      blurb="What's the Stitch is an all-inclusive embroidery kit for beginners. The main graphic element in its packaging is hand-drawn stitch patterns, highlighting the ones taught in the instruction booklet."
      jobTitle="Drexel graphic design student"
    >
      <Grid sx={{ paddingBottom: "0px" }}>
        <MaxGridContainer>
          <Grid container spacing={6} paddingBottom="100px">
            <Grid item xs={12}>
              <Grid container spacing={6} alignItems="flex-end">
                <Grid item xs={6}>
                  <img
                    src="/img/embroidery/top.jpg"
                    alt="What's the Stitch embroidery kit packaging"
                    width="100%"
                  />
                </Grid>
                <Grid item xs={6}>
                  <img
                    src="/img/embroidery/closeUp.jpg"
                    alt="What's the Stitch embroidery kit packaging"
                    width="100%"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <img
                src="/img/embroidery/open.jpg"
                alt="What's the Stitch embroidery kit packaging"
                width="100%"
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src="/img/embroidery/lid.jpg"
                alt="What's the Stitch embroidery kit packaging"
                width="100%"
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src="/img/embroidery/string.jpg"
                alt="What's the Stitch embroidery kit packaging"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src="/img/embroidery/side.jpg"
                alt="What's the Stitch embroidery kit packaging"
                width="100%"
              />
            </Grid>
            <ShowMdGrid item xs={3} />
            <Grid item xs={3}>
              <img
                src="/img/embroidery/howTo.jpg"
                alt="What's the Stitch embroidery kit packaging"
                width="100%"
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src="/img/embroidery/howToOpen.jpg"
                alt="What's the Stitch embroidery kit packaging"
                width="100%"
              />
            </Grid>
          </Grid>
        </MaxGridContainer>
      </Grid>
    </ProjectPage>
  </PageTemplate>
);

export default Embroidery;
