import React from "react";

import Grid from "@mui/material/Grid";
import PageTemplate, { MaxGridContainer } from "components/PageTemplate";
import { Typography } from "@mui/material";
import ProjectPage from "components/projectPage";
import styled from "styled-components";
import { bgGradient } from "components/common";

const blurb = (
  <>
    <p>P1</p>
    <p>P2</p>
  </>
);

const Bud = () => {
  return (
    <PageTemplate>
      <ProjectPage title="Company" blurb={blurb} jobTitle="Job title">
        <Grid sx={{ paddingBottom: "0px", background: bgGradient }}>
          <MaxGridContainer>
            <Grid container spacing={6} sx={{ paddingBottom: "80px" }}>
              <Grid item xs={12}>
                <Typography variant="h3" textAlign="center" gutterBottom>
                  Application design
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <img
                  src="/img/ihs/jda-details.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>
            </Grid>
          </MaxGridContainer>
        </Grid>
      </ProjectPage>
    </PageTemplate>
  );
};
export default Bud;
