import React from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { Typography, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { MaxGridContainer } from "./PageTemplate";
import theme from "app/theme";

const BreadcrumbText = styled(Typography)`
  font-size: 1.25rem !important;
  cursor: pointer;
  position: relative;
  z-index: 1;
  padding: 8px;
  margin: 0px -8px !important;
  ${theme.breakpoints.up("md")} {
    font-size: 22px;
  }
`;

const TitleText = styled(Typography)`
  font-size: 70px !important;
  font-weight: 700 !important;
  ${theme.breakpoints.up("sm")} {
    font-size: 80px !important;
  }
  ${theme.breakpoints.up("md")} {
    font-size: 90px !important;
  }
  ${theme.breakpoints.up("lg")} {
    font-size: 100px !important;
  }
`;

const GradientBg = styled(Grid)`
  background: rgb(255, 255, 255);
  background: linear-gradient(rgb(243, 242, 241) 0%, rgb(255, 255, 255) 100%);
  padding-top: 30px;
  padding-bottom: 70px;
  ${theme.breakpoints.up("sm")} {
    padding-bottom: 85px;
  }
  ${theme.breakpoints.up("md")} {
    padding-bottom: 100px;
  }
`;

const ChildrenSection = styled(Grid)`
  padding-top: 90px;
`;

export const AbsoluteText = ({ children, width = "100%", display }) => (
  <Typography
    variant="body2"
    position="absolute"
    bottom="-18px"
    left="0px"
    sx={{ fontSize: "13px", width: width }}
    display={display}
  >
    {children}
  </Typography>
);

export const negativeMarginLeft = {
  xs: "-7px",
  sm: "-8px",
  md: "-9px",
  lg: "-10px",
};

const ProjectPage = ({ title, blurb, children, jobTitle, headerImg }) => {
  const navigate = useNavigate();

  return (
    <>
      <GradientBg>
        <MaxGridContainer className="maxGridContainer">
          <Grid
            id="projectPageOuterGrid"
            container
            alignItems="center"
            spacing={{ md: 6, lg: 10 }}
          >
            <Grid item xs={12}>
              <BreadcrumbText
                variant="h6"
                display="inline"
                onClick={() => {
                  navigate("/");
                }}
              >
                Work&nbsp;&nbsp;/&nbsp;&nbsp;
              </BreadcrumbText>

              <TitleText
                variant="h1"
                sx={{
                  marginTop: "-10px",
                  marginBottom: "15px",
                }}
              >
                {title}
              </TitleText>

              {jobTitle && (
                <Typography variant="h6" gutterBottom>
                  {jobTitle}
                </Typography>
              )}

              <Typography sx={{ maxWidth: { md: "75%", lg: "65%" } }}>
                {blurb}
              </Typography>
            </Grid>
          </Grid>
        </MaxGridContainer>
      </GradientBg>
      <ChildrenSection>{children}</ChildrenSection>
    </>
  );
};
export default ProjectPage;
