import React from "react";

import Grid from "@mui/material/Grid";
import PageTemplate, { MaxGridContainer } from "components/PageTemplate";
import ProjectPage from "components/projectPage";

const Giacometti = () => (
  <PageTemplate>
    <ProjectPage
      title="Artist catalogue"
      blurb="This artist catalogue about Alberto Giacometti was inspired by his life and art. His elongated, existential figures are mirrored in the graphic typography. Expressive full-page images interrupt the minimalist composition of the other pages to illustrate the disruption the war had in Giacometti's life."
      jobTitle="Drexel graphic design student"
    >
      <Grid sx={{ paddingBottom: "100px" }}>
        <MaxGridContainer>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <img
                src="/img/giacometti/cover.jpg"
                alt="Artist catalogue spread"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src="/img/giacometti/intro.jpg"
                alt="Artist catalogue spread"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src="/img/giacometti/landscape.jpg"
                alt="Artist catalogue spread"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src="/img/giacometti/page2.jpg"
                alt="Artist catalogue spread"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src="/img/giacometti/earlyWork.jpg"
                alt="Artist catalogue spread"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src="/img/giacometti/page3.jpg"
                alt="Artist catalogue spread"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src="/img/giacometti/bombing.jpg"
                alt="Artist catalogue spread"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src="/img/giacometti/ww2.jpg"
                alt="Artist catalogue spread"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src="/img/giacometti/statues.jpg"
                alt="Artist catalogue spread"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src="/img/giacometti/arm.jpg"
                alt="Artist catalogue spread"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src="/img/giacometti/text.jpg"
                alt="Artist catalogue spread"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src="/img/giacometti/page4.jpg"
                alt="Artist catalogue spread"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src="/img/giacometti/face.jpg"
                alt="Artist catalogue spread"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src="/img/giacometti/working.jpg"
                alt="Artist catalogue spread"
                width="100%"
              />
            </Grid>
          </Grid>
        </MaxGridContainer>
      </Grid>
    </ProjectPage>
  </PageTemplate>
);
export default Giacometti;
