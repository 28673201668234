import React from "react";

import Grid from "@mui/material/Grid";
import PageTemplate, { MaxGridContainer } from "components/PageTemplate";
import { Typography } from "@mui/material";
import ProjectPage from "components/projectPage";
import styled from "styled-components";
import { bgGradient } from "components/common";

const blurb = (
  <>
    <p>
      After a brainstorming session to discuss the company's goals and identity,
      I designed and developed a brand for Alliance that is inviting to a broad
      range of patients, yet bold and stable, conveying a sense of
      trustworthiness and professionalism that would appeal to individuals
      seeking dependable and family-friendly healthcare services.
    </p>
  </>
);

const SwatchGrid = ({ color }) => (
  <Grid item xs={3} backgroundColor={color} height="16px" />
);

const FullBleedGrid = ({ children, ...props }) => (
  <Grid width="calc(100% + 20px)" marginLeft="-10px" {...props}>
    {children}
  </Grid>
);

const SplitGridFullBleed = ({ children, ...props }) => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    width="100%"
    height="100%"
    maxHeight="600px"
    maxWidth="600px"
    {...props}
  >
    {children}
  </Grid>
);

const Alliance = () => {
  return (
    <PageTemplate>
      <ProjectPage
        title="Alliance Primary Health"
        blurb={blurb}
        jobTitle="Visual brand designer"
      >
        <Grid sx={{ background: "#F2F1ED" }}>
          {/* -------------- LOGO & BREAKDOWN -------------- */}
          <MaxGridContainer>
            <Grid container spacing={6} sx={{ paddingBottom: "80px" }}>
              <Grid item item xs={12} display="flex" justifyContent="center">
                <img
                  src="/img/alliance/logo.png"
                  width="100%"
                  style={{ maxWidth: "350px", margin: "80px 0px" }}
                />
              </Grid>

              <Grid item xs={12} display="flex" justifyContent="center">
                <img
                  src="/img/alliance/breakdown.png"
                  width="100%"
                  style={{ maxWidth: "460px", margin: "80px 0px 120px" }}
                />
              </Grid>
            </Grid>
          </MaxGridContainer>

          {/* -------------- WHITE ON NAVY -------------- */}
          <FullBleedGrid
            sx={{
              backgroundColor: "#09487D",
            }}
          >
            <MaxGridContainer>
              <Grid container spacing={6} sx={{ paddingBottom: "80px" }}>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <img
                    src="/img/alliance/logo_light.png"
                    width="100%"
                    style={{ maxWidth: "340px", margin: "70px 0px 50px" }}
                  />
                </Grid>
              </Grid>
            </MaxGridContainer>
          </FullBleedGrid>

          {/* -------------- SPLIT LOGOS -------------- */}
          <FullBleedGrid
            backgroundColor="#fff"
            position="relative"
            height="50vw"
            maxHeight="500px"
          >
            <Grid
              container
              position="absolute"
              alignItems="stretch"
              height="50vw"
              maxHeight="500px"
            >
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                <SplitGridFullBleed>
                  <img
                    src="/img/alliance/A.png"
                    width="100%"
                    style={{
                      maxWidth: "170px",
                      maxHeight: "170px",
                    }}
                  />
                </SplitGridFullBleed>
              </Grid>

              <Grid
                item
                xs={6}
                backgroundColor="#27A675"
                display="flex"
                justifyContent="flex-start"
              >
                <SplitGridFullBleed>
                  <img
                    src="/img/alliance/circular.png"
                    width="100%"
                    style={{
                      maxWidth: "220px",
                      maxHeight: "220px",
                    }}
                  />
                </SplitGridFullBleed>
              </Grid>
            </Grid>
          </FullBleedGrid>

          {/* -------------- PALETTE -------------- */}
          <FullBleedGrid container>
            <SwatchGrid color="#D3EADE" />
            <SwatchGrid color="#27A675" />
            <SwatchGrid color="#09487D" />
            <SwatchGrid color="#D0E2EA" />
          </FullBleedGrid>

          {/* -------------- MOCKUPS -------------- */}
          <MaxGridContainer>
            <Grid container spacing={4} padding="50px 0px">
              <Grid item xs={12} sm={6}>
                <img src="/img/alliance/flags.jpg" width="100%" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <img src="/img/alliance/bag.jpg" width="100%" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <img src="/img/alliance/cards.jpg" width="100%" />
                  </Grid>
                  <Grid item xs={12}>
                    <img src="/img/alliance/envelope.jpg" width="100%" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <img src="/img/alliance/bottle.jpg" width="100%" />
              </Grid>

              {/* <Grid item xs={12}>
                <img src="/img/alliance/pattern.png" width="100%" />
              </Grid> */}
            </Grid>
          </MaxGridContainer>

          {/* -------------- PATTERN -------------- */}
          <FullBleedGrid
            height="400px"
            sx={{
              backgroundImage: "url(./img/alliance/pattern.png)",
              backgroundSize: "cover",
              marginBottom: "-10px",
            }}
          ></FullBleedGrid>
        </Grid>
      </ProjectPage>
    </PageTemplate>
  );
};
export default Alliance;
