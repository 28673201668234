import React from "react";

import Grid from "@mui/material/Grid";
import PageTemplate, { MaxGridContainer } from "components/PageTemplate";
import ProjectPage, { AbsoluteText } from "components/projectPage";
import theme from "app/theme";
import { negativeMarginLeft } from "components/projectPage";
import { bgGradient } from "components/common";

const gridSpacing = { xs: 2, sm: 3, md: 4 };

const Twill = () => {
  return (
    <PageTemplate>
      <ProjectPage
        title="Twill"
        jobTitle="Graphic Designer"
        blurb="While freelancing for Twill, I created various social media and in-house graphics and illustrations, several of which were animated to boost engagement."
      >
        <Grid
          container
          spacing={{ xs: 8, md: 10, lg: 14 }}
          sx={{
            paddingBottom: "100px",
            background: bgGradient,
          }}
        >
          <Grid item xs={12}>
            <MaxGridContainer>
              <Grid position="relative">
                <img
                  src="/img/twill/TimeOutside.jpg"
                  alt="Time outside"
                  width="100%"
                />
                <AbsoluteText>
                  Article about why time outside is good for you
                </AbsoluteText>
              </Grid>
            </MaxGridContainer>
          </Grid>

          <Grid item xs={12}>
            <MaxGridContainer>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={6} sm={4} lg={3}>
                  <Grid position="relative">
                    <img
                      src="/img/twill/milestones/comment.gif"
                      alt="Comments"
                      width="100%"
                    />
                    <AbsoluteText
                      width="400px"
                      display={{ xs: "none", sm: "block" }}
                    >
                      Posts about user's engagement milestones
                    </AbsoluteText>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={4} lg={3}>
                  <img
                    src="/img/twill/milestones/post.gif"
                    alt="Posts"
                    width="100%"
                  />
                </Grid>
                <Grid item xs={6} sm={4} lg={3}>
                  <Grid position="relative">
                    <img
                      src="/img/twill/milestones/articles.gif"
                      alt="Articles"
                      width="100%"
                    />
                    <AbsoluteText
                      width="400px"
                      display={{ xs: "block", sm: "none" }}
                    >
                      Posts about user's engagement milestones
                    </AbsoluteText>
                  </Grid>
                </Grid>
              </Grid>
            </MaxGridContainer>
          </Grid>

          <Grid item xs={12}>
            <MaxGridContainer>
              <Grid container spacing={gridSpacing}>
                <Grid
                  item
                  sm={4}
                  lg={6}
                  position="relative"
                  display={{ xs: "none", sm: "block" }}
                >
                  {/* --------- HAND WAVE --------- */}
                  <Grid
                    display="flex"
                    justifyContent="flex-end"
                    sx={{
                      position: "absolute",
                      left: { xs: "-30px", lg: 0 },
                      bottom: {
                        sm: "-30px",
                        md: "-30px",
                        lg: "0px",
                      },
                      width: { xs: "100%", md: "80%", lg: "50%" },
                    }}
                  >
                    <img src="/img/twill/wave.gif" alt="Wave" width="100%" />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={8} lg={6}>
                  <Grid position="relative">
                    <img
                      src="/img/twill/2022.gif"
                      alt="2022 Wrapped"
                      width="100%"
                    />
                    <AbsoluteText>In-app year review</AbsoluteText>
                  </Grid>
                </Grid>
              </Grid>
            </MaxGridContainer>
          </Grid>

          <Grid item xs={12}>
            <Grid
              sx={{
                backgroundColor: theme.palette.navy.main,
                width: "100vw",
                marginLeft: negativeMarginLeft,
                padding: "44px 0px",
              }}
            >
              <MaxGridContainer>
                <img src="/img/twill/drinks.jpg" alt="Drinks" width="100%" />
              </MaxGridContainer>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <MaxGridContainer>
              <Grid container spacing={gridSpacing}>
                <Grid
                  item
                  xs={6}
                  sm={3}
                  sx={{ display: { xs: "block", sm: "none", lg: "block" } }}
                ></Grid>
                <Grid item xs={6} sm={4} lg={3}>
                  <Grid position="relative">
                    <img
                      src="/img/twill/stress/stat.png"
                      alt="Stress statistics"
                      width="100%"
                    />
                    <AbsoluteText
                      width="500px"
                      display={{ xs: "none", sm: "block" }}
                    >
                      Stress Awareness Month series
                    </AbsoluteText>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={4} lg={3}>
                  <Grid position="relative">
                    <img
                      src="/img/twill/stress/breathing.gif"
                      alt="Box breathing"
                      width="100%"
                    />
                    <AbsoluteText
                      width="500px"
                      display={{ xs: "block", sm: "none" }}
                    >
                      Stress Awareness Month series
                    </AbsoluteText>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={4} lg={3}>
                  <img
                    src="/img/twill/stress/massage.gif"
                    alt="Self-massage"
                    width="100%"
                  />
                </Grid>
              </Grid>
            </MaxGridContainer>
          </Grid>

          <Grid item xs={12}>
            <MaxGridContainer>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={6} display="flex" alignItems="center">
                  <Grid position="relative">
                    <img
                      src="/img/twill/habits/1.jpg"
                      alt="Healthy Habits"
                      width="100%"
                    />
                    <AbsoluteText
                      width="500px"
                      display={{ xs: "none", sm: "block" }}
                    >
                      Healthy Habits series
                    </AbsoluteText>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={6}>
                      <img
                        src="/img/twill/habits/2.jpg"
                        alt="Healthy Habits"
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <img
                        src="/img/twill/habits/3.jpg"
                        alt="Healthy Habits"
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Grid position="relative">
                        <img
                          src="/img/twill/habits/4.jpg"
                          alt="Healthy Habits"
                          width="100%"
                        />
                        <AbsoluteText
                          width="500px"
                          display={{ xs: "block", sm: "none" }}
                        >
                          Healthy Habits series
                        </AbsoluteText>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <img
                        src="/img/twill/habits/5.jpg"
                        alt="Healthy Habits"
                        width="100%"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MaxGridContainer>
          </Grid>

          <Grid item xs={12}>
            <MaxGridContainer>
              <Grid container spacing={gridSpacing}>
                <Grid
                  item
                  xs={6}
                  md={4}
                  lg={3}
                  display="flex"
                  alignItems="center"
                >
                  <Grid position="relative">
                    <img
                      src="/img/twill/gaslighting/1.jpg"
                      alt="Gaslighting"
                      width="100%"
                    />
                    <AbsoluteText
                      width="500px"
                      display={{ xs: "none", md: "block" }}
                    >
                      Gaslighting post
                    </AbsoluteText>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                  lg={3}
                  display="flex"
                  alignItems="center"
                >
                  <img
                    src="/img/twill/gaslighting/2.jpg"
                    alt="Gaslighting"
                    width="100%"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                  lg={3}
                  display="flex"
                  alignItems="center"
                >
                  <Grid position="relative">
                    <img
                      src="/img/twill/gaslighting/3.jpg"
                      alt="Gaslighting"
                      width="100%"
                    />
                    <AbsoluteText
                      width="500px"
                      display={{ xs: "block", md: "none" }}
                    >
                      Gaslighting post
                    </AbsoluteText>
                  </Grid>
                </Grid>
              </Grid>
            </MaxGridContainer>
          </Grid>

          {/* <Grid item xs={12}>
            <MaxGridContainer>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={6} md={6}>
                  <img
                    src="/img/twill/loneliness.jpg"
                    alt="Loneliness"
                    width="100%"
                  />
                </Grid>

                <Grid item xs={6} md={3} display="flex" alignItems="flex-end">
                  <img
                    src="/img/twill/snow.gif"
                    alt="Loneliness"
                    width="100%"
                  />
                </Grid>

                <Grid item xs={6} md={3} display="flex" alignItems="flex-end">
                  <img
                    src="/img/twill/champion.png"
                    alt="Community champion"
                    width="100%"
                  />
                </Grid>
                <Grid item xs={6} md={3} />
                <Grid item xs={6} md={3}>
                  <img
                    src="/img/twill/grief.gif"
                    alt="Loneliness"
                    width="100%"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <img
                    src="/img/twill/shareYourStory.jpg"
                    alt="Share your story"
                    width="100%"
                  />
                </Grid>
              </Grid>
            </MaxGridContainer>
          </Grid> */}
        </Grid>
      </ProjectPage>
    </PageTemplate>
  );
};
export default Twill;
