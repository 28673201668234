import React, { useState, useEffect } from "react";

// external
import styled from "styled-components";

// Mui
import { Grid } from "@mui/material";

// App
import Nav from "./nav";
import theme from "app/theme";

const ColorStripeBg = styled(Grid)`
  background: rgb(27, 37, 45);
  height: 100vh;
  overflow: visible;
  background: linear-gradient(
    170deg,
    rgba(27, 37, 45, 1) -2%,
    rgba(49, 167, 170, 1) 12%,
    rgba(242, 227, 218, 1) 16%,
    rgba(255, 180, 155, 1) 30%,
    rgba(214, 161, 191, 1) 56%,
    rgba(214, 161, 191, 0) 77%
  );
  padding: 7px 7px 0px;
  ${theme.breakpoints.up("sm")} {
    padding: 8px 8px 0px;
  }
  ${theme.breakpoints.up("md")} {
    padding: 9px 9px 0px;
  }
  ${theme.breakpoints.up("lg")} {
    padding: 10px 10px 0px;
  }
`;

const BgInner = styled(Grid)`
  background-color: ${(props) =>
    props.isHome ? "#1b252d" : theme.palette.white.light};
`;

export const MaxGridContainer = styled(Grid)`
  padding: 0px 25px;
  margin: 0 auto;
  max-width: 1200px;
  ${theme.breakpoints.up("sm")} {
    padding: 0px 40px;
  }
`;

const StyledGrid = styled(Grid)`
  position: relative;
  z-index: 10;
`;

const Blob = styled.img`
  position: absolute;
  z-index: 1;
  pointer-events: none;
`;

const TealBlob = styled(Blob)`
  opacity: 0.4;
  left: -700px;
  ${theme.breakpoints.up("sm")} {
    left: -600px;
  }
  ${theme.breakpoints.up("lg")} {
    left: -300px;
  }
`;

const PurpleBlob = styled(Blob)`
  opacity: 0.3;
`;

const OverflowContainer = styled(Grid)`
  position: relative;
  overflow-x: hidden;
  width: 100vw;
  margin-left: -7px;
  padding: 7px;
  ${theme.breakpoints.up("sm")} {
    margin-left: -8px;
    padding: 8px;
  }
  ${theme.breakpoints.up("md")} {
    margin-left: -9px;
    padding: 9px;
  }
  ${theme.breakpoints.up("lg")} {
    margin-left: -10px;
    padding: 10px;
  }
`;

const PageTemplate = ({ isHome, pageName, children, isCharacterCo }) => {
  // Check for Safari
  // const userAgent = navigator.userAgent;
  // const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  const [scrolled, setScrolled] = useState(0);

  const onScroll = () => {
    setScrolled(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  // set scrolled
  useEffect(() => {
    window.scrollTo(0, 0);
    setScrolled(0);
  }, [window]);

  // console.log(window.location);

  // change page background color
  useEffect(() => {
    if (isHome) {
      document.body.classList.add("home-page");
    } else {
      document.body.classList.add("other-page");
    }

    // Clean up when the component unmounts
    return () => {
      document.body.classList.remove("home-page", "other-page");
    };
  }, [isHome]);

  return isCharacterCo ? (
    <Grid style={{ backgroundColor: "#fff" }}>{children}</Grid>
  ) : (
    <ColorStripeBg>
      <BgInner isHome={isHome}>
        <OverflowContainer>
          {isHome && (
            <>
              <TealBlob
                src="/img/teal2.png"
                id="blob1"
                width="1200px"
                style={{
                  top: `${scrolled * 0.55 - 240}px`,
                  transform: `rotate(-${scrolled * 0.05}deg)`,
                }}
              />
              <PurpleBlob
                src="/img/purple2.png"
                id="blob2"
                width="1400px"
                style={{
                  top: `${scrolled * 0.5 + 200}px`,
                  right: `${scrolled * 0.05 - 650}px`,
                  transform: `rotate(${scrolled * 0.06}deg)`,
                }}
              />
            </>
          )}
          <StyledGrid id="styledGrid">
            <Nav isHome={isHome} />

            <Grid id="childrenContainer">{children}</Grid>
          </StyledGrid>
        </OverflowContainer>
      </BgInner>
    </ColorStripeBg>
  );
};

export default PageTemplate;
