import React from "react";

import PageTemplate, { MaxGridContainer } from "components/PageTemplate";
import ProjectPage from "components/projectPage";

// motion graphics for the intro, outro, and website examples (Youtube Creator Academy, Johnson & Johnson, Health Nucleus, Newera, and Canadian Tire) in the demo reel

const EPAM = () => {
  return (
    <PageTemplate>
      <ProjectPage
        title="EPAM"
        blurb="During my co-op (internship) at EPAM, I was entrusted with the task of crafting a demo reel which would serve as a showcase for EPAM's capabilities to prospective clients. Using only static designs and raw video files, I was responsible for the video editing and motion graphics of this reel."
        jobTitle="Associate Visual Designer"
      >
        <MaxGridContainer sx={{ paddingBottom: "80px" }}>
          <video width="100%" controls>
            <source src="/img/DemoReel.mp4" type="video/mp4" />
          </video>
        </MaxGridContainer>
      </ProjectPage>
    </PageTemplate>
  );
};
export default EPAM;
