import React from "react";

import Grid from "@mui/material/Grid";
import PageTemplate, { MaxGridContainer } from "components/PageTemplate";
import { Typography } from "@mui/material";
import ProjectPage, { AbsoluteText } from "components/projectPage";
import { bgGradient } from "components/common";

const gridSpacing = { xs: 2, sm: 3, md: 4, lg: 6 };

const showMd = { xs: "none", md: "block" };
const hideMd = { xs: "block", md: "none" };

const Halloween = ({ display }) => (
  <Grid item xs={12} md={3} display={display}>
    <Grid position="relative">
      <img
        src="/img/risewell/halloween.jpeg"
        alt="Risewell halloween"
        width="100%"
      />
      <AbsoluteText>Halloween sale campaign</AbsoluteText>
    </Grid>
  </Grid>
);

const Remember = ({ display }) => (
  <Grid item xs={12} md={3} display={display}>
    <Grid position="relative">
      <img
        src="/img/risewell/memorial/products.jpg"
        alt="Remember, honor, smile"
        width="100%"
      />
      <AbsoluteText width="400px" display={hideMd}>
        Memorial Day sale campaign
      </AbsoluteText>
    </Grid>
  </Grid>
);

const Kids = ({ display }) => (
  <Grid item xs={12} md={3} display={display}>
    <img
      src="/img/risewell/memorial/kids.jpg"
      alt="Kids products with stars and fireworks"
      width="100%"
    />
  </Grid>
);

const Risewell = () => {
  return (
    <PageTemplate>
      <ProjectPage
        title="Risewell"
        jobTitle="Graphic Designer"
        blurb="While freelancing for Risewell, I created various social media graphics and email campaigns, several of which were animated to significantly boost engagement."
      >
        {/* --------- CHRISTMAS --------- */}
        <Grid
          container
          spacing={{ xs: 12, md: 15, lg: 18 }}
          sx={{
            paddingBottom: "100px",
            background: bgGradient,
          }}
        >
          <Grid item xs={12}>
            <MaxGridContainer>
              <Grid container spacing={gridSpacing} alignItems="flex-end">
                <Grid item xs={12}>
                  <Typography variant="h3" textAlign="center" gutterBottom>
                    Product photography and graphics for Holiday sale campaign
                  </Typography>
                </Grid>

                <Grid item xs={12} md={9}>
                  <img
                    src="/img/risewell/christmas/tray.jpg"
                    alt="Risewell products and holiday decorations"
                    width="100%"
                  />
                </Grid>
                <Grid item xs={8} sm={6} md={3}>
                  <img
                    src="/img/risewell/christmas/wreath-logo.gif"
                    alt="Wreath gif"
                    width="100%"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={gridSpacing}
                sx={{ marginTop: "0px !important" }}
              >
                <Grid item xs={6} md={3} display={showMd}>
                  <img
                    src="/img/risewell/christmas/email.jpeg"
                    alt="Holiday email"
                    width="100%"
                  />
                </Grid>

                <Grid item xs={12} md={9}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={8}>
                      <img
                        src="/img/risewell/christmas/toy.jpg"
                        alt="Kids toys"
                        width="100%"
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Grid container spacing={gridSpacing}>
                        <Grid item xs={5} md={12}>
                          <img
                            src="/img/risewell/christmas/friday-blue.gif"
                            alt="Friday gif"
                            width="100%"
                          />
                        </Grid>
                        <Grid item xs={7} md={12}>
                          <img
                            src="/img/risewell/christmas/gift-box-square.gif"
                            alt="Gif of Risewell products floating out of Christmas box"
                            width="100%"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                    >
                      <img
                        src="/img/risewell/christmas/103A1011.jpg"
                        alt="Risewell products with holiday decorations"
                        width="100%"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MaxGridContainer>
          </Grid>

          {/* --------- VALENTINES --------- */}
          <Grid item xs={12}>
            <MaxGridContainer>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <Typography variant="h3" textAlign="center" gutterBottom>
                    Graphics for smaller holidays
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <Grid position="relative">
                        <img
                          src="/img/risewell/valentines/103A1692.jpg"
                          alt="Risewell products with heart chocolates"
                          width="100%"
                        />
                        <AbsoluteText display={showMd}>
                          Product photography for Valentines Day sale campaign
                        </AbsoluteText>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} display={showMd} />
                    <Grid item xs={6} display={hideMd}>
                      <img
                        src="/img/risewell/valentines/103A1486.jpg"
                        alt="Risewell products with heart chocolates"
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <img
                        src="/img/risewell/valentines/103A1363.jpg"
                        alt="Risewell products in rose bouquet"
                        width="100%"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={6} display={showMd}>
                      <img
                        src="/img/risewell/valentines/103A1486.jpg"
                        alt="Risewell kids products, photos, and heart candies"
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid position="relative">
                        <img
                          src="/img/risewell/valentines/103A1742.jpg"
                          alt="Risewell products and holiday decorations"
                          width="100%"
                        />
                        <AbsoluteText display={{ xs: "block", md: "none" }}>
                          Valentines Day sale campaign
                        </AbsoluteText>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MaxGridContainer>
          </Grid>

          {/* --------- 4TH OF JULY --------- */}
          <Grid item xs={12}>
            <MaxGridContainer>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={6} md={3}>
                  <Grid position="relative">
                    <img
                      src="/img/risewell/4th/cupcake.gif"
                      alt="4th of July gif"
                      width="100%"
                    />
                    <AbsoluteText display={showMd}>
                      4th of July sale campaign
                    </AbsoluteText>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={3} display="flex" alignItems="flex-end">
                  <img
                    src="/img/risewell/4th/watermelon.gif"
                    alt="4th of July gif"
                    width="100%"
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <Grid position="relative">
                    <img
                      src="/img/risewell/4th/fireworks.gif"
                      alt="4th of July gif"
                      width="100%"
                    />
                    <AbsoluteText display={hideMd}>
                      4th of July sale campaign
                    </AbsoluteText>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={3}>
                  <img
                    src="/img/risewell/4th/star.gif"
                    alt="4th of July gif"
                    width="100%"
                  />
                </Grid>
              </Grid>
            </MaxGridContainer>
          </Grid>

          {/* --------- OTHER --------- */}
          <Grid item xs={12}>
            <MaxGridContainer>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={6}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <Grid position="relative">
                        <img
                          src="/img/risewell/earth.jpg"
                          alt="Risewell product in bush with flowers"
                          width="100%"
                        />
                        <AbsoluteText>Earth Day</AbsoluteText>
                      </Grid>
                    </Grid>
                    <Halloween display={hideMd} />
                  </Grid>
                </Grid>
                <Halloween display={showMd} />
                <Grid item xs={6} md={3}>
                  <Grid position="relative">
                    <img
                      src="/img/risewell/mothersDay.jpeg"
                      alt="Mothers day email"
                      width="100%"
                    />
                    <AbsoluteText>Mother's Day</AbsoluteText>
                  </Grid>
                </Grid>
              </Grid>
            </MaxGridContainer>
          </Grid>

          {/* --------- MEMORIAL DAY --------- */}
          <Grid item xs={12}>
            <MaxGridContainer>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={6} md={3}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <Grid position="relative">
                        <img
                          src="/img/risewell/memorial/stars.jpg"
                          alt="Risewell products with stars"
                          width="100%"
                        />
                        <AbsoluteText width="400px" display={showMd}>
                          Memorial Day sale campaign
                        </AbsoluteText>
                      </Grid>
                    </Grid>
                    <Remember display={hideMd} />
                  </Grid>
                </Grid>

                <Grid item xs={6} md={3}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <img
                        src="/img/risewell/memorial/square.jpg"
                        alt="Risewell products"
                        width="100%"
                      />
                    </Grid>
                    <Kids display={hideMd} />
                  </Grid>
                </Grid>

                <Kids display={showMd} />

                <Remember display={showMd} />
              </Grid>
            </MaxGridContainer>
          </Grid>

          {/* --------- FLAVORLESS --------- */}
          <Grid item xs={12}>
            <MaxGridContainer>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <Typography variant="h3" textAlign="center" gutterBottom>
                    Graphics for new product line releases
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Grid position="relative">
                    <img
                      src="/img/risewell/flavorless/blue.jpg"
                      alt="Flavorless products"
                      width="100%"
                    />
                    <AbsoluteText width="400px" display={showMd}>
                      Flavorless Mineral Toothpaste campaign
                    </AbsoluteText>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <img
                    src="/img/risewell/flavorless/white.jpg"
                    alt="Why Flavorless?"
                    width="100%"
                  />
                </Grid>
              </Grid>
            </MaxGridContainer>
          </Grid>

          {/* --------- PRO --------- */}
          <Grid item xs={12}>
            <MaxGridContainer>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={6}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={6}>
                      <Grid position="relative">
                        <img
                          src="/img/risewell/pro/kids.gif"
                          alt="Kids pro facts"
                          width="100%"
                        />
                        <AbsoluteText display={showMd}>
                          Pro Mineral Toothpaste campaign
                        </AbsoluteText>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid position="relative">
                        <img
                          src="/img/risewell/pro/email.gif"
                          alt="Kids pro facts"
                          width="100%"
                        />
                        <AbsoluteText width="400px" display={hideMd}>
                          Pro Mineral Toothpaste campaign
                        </AbsoluteText>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={6}>
                      <img
                        src="/img/risewell/pro/pro.jpg"
                        alt="Pro email"
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} display={showMd} />
                    <Grid item xs={12} md={6}>
                      <img
                        src="/img/risewell/pro/coming.gif"
                        alt="Pro products"
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <img
                        src="/img/risewell/pro/sneakPeak.gif"
                        alt="Coming soon graphic"
                        width="100%"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MaxGridContainer>
          </Grid>
        </Grid>
      </ProjectPage>
    </PageTemplate>
  );
};
export default Risewell;
