import React from "react";

import Grid from "@mui/material/Grid";
import PageTemplate, { MaxGridContainer } from "components/PageTemplate";
import { Card, Typography } from "@mui/material";
import ProjectPage from "components/projectPage";
import styled from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "app/theme";
import { bgGradient } from "components/common";

const blurb = (
  <>
    <p>
      During this project, I collaborated with two other developers to create a
      custom business management web application for an occupational health
      company. This application includes features for patients, employees,
      forms, reports, schedules, and other daily tasks.
    </p>
    <p>
      After communicating with the client and my teammates to establish software
      requirements, I created an easy-to-learn and polished UI system, focusing
      on intuitive and consistent visual elements for easy pattern recognition
      and usability, and presented a finalized comprehensive prototype of my
      design. Working with the other developers, I architected and coded the
      front-end of the application in React.js with a focus on scalability,
      efficiency, and meticulous design accuracy. Throughout the project's
      duration, I managed our tasks and priorities to meet project milestones.
    </p>
  </>
);

const IHS = () => {
  return (
    <PageTemplate>
      <ProjectPage
        title="Industry Health Solutions"
        blurb={blurb}
        jobTitle="Lead Visual Designer, Front-End Developer, Project Manager"
      >
        <Grid sx={{ paddingBottom: "0px", background: bgGradient }}>
          <MaxGridContainer>
            <Grid container spacing={6} sx={{ paddingBottom: "80px" }}>
              <Grid item xs={12}>
                <Typography variant="h3" textAlign="center" gutterBottom>
                  Application design
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <img
                  src="/img/ihs/jda-details.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <img
                      src="/img/ihs/client.jpg"
                      width="100%"
                      className="shadow"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <img
                      src="/img/ihs/dashboard.jpg"
                      width="100%"
                      className="shadow"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <img
                      src="/img/ihs/hpe.jpg"
                      width="100%"
                      className="shadow"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <img src="/img/ihs/roles.jpg" width="100%" className="shadow" />
              </Grid>
              <Grid item xs={12} md={4}>
                <img
                  src="/img/ihs/patient.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <img
                  src="/img/ihs/encounter.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <img
                  src="/img/ihs/job-info.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <img
                  src="/img/ihs/requirements.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>

              {/*
               <Grid item xs={12}>
                <Grid
                  container
                  flexWrap={{ xs: "wrap", lg: "nowrap" }}
                  sx={{
                    width: {
                      xs: "calc(100% + 45px)",
                      lg: "calc(100% + 40px)",
                      xl: "calc(100% + 70px)",
                    },
                  }}
                >
                  <Grid
                    sx={{
                      marginTop: {
                        xs: "-30px",
                        md: "-10px",
                        lg: "150px",
                        xl: "230px",
                      },
                      marginLeft: {
                        xs: "-47px",
                        md: "-16vw",
                        lg: "-9vw",
                        xl: "-120px",
                      },
                      width: {
                        xs: "100vw",
                        md: "82vw",
                        lg: "56vw",
                        xl: "60%",
                      },
                      height: {
                        xs: "107vw",
                        md: "86vw",
                        lg: "650px",
                        xl: "740px",
                      },
                    }}
                    className="ihs-imgTopLeft"
                  />
                  <Grid
                    sx={{
                      marginTop: { xs: "-40px", md: "-70px", lg: "-30px" },
                      marginLeft: {
                        xs: "-30px",
                        md: "11vw",
                        lg: "-5vw",
                        xl: "-90px",
                      },
                      width: {
                        xs: "100vw",
                        md: "83vw",
                        lg: "54vw",
                        xl: "60%",
                      },
                      height: {
                        xs: "87vw",
                        md: "79vw",
                        lg: "600px",
                        xl: "770px",
                      },
                    }}
                    className="ihs-imgTopRight"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  sx={{
                    marginTop: {
                      xs: "-80px",
                      md: "-145px",
                      lg: "-50px",
                      xl: "-70px",
                    },
                    marginLeft: {
                      xs: "-17vw",
                      md: "-10vw",
                      lg: "0vw",
                      xl: "0vw",
                    },
                    width: {
                      xs: "150vw",
                      md: "99vw",
                      lg: "89vw",
                      xl: "100%",
                    },
                    height: {
                      xs: "86vw",
                      md: "58vw",
                      lg: "52vw",
                      xl: "670px",
                    },
                  }}
                  className="ihs-imgCenter"
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  sx={{
                    marginTop: {
                      xs: "-15px",
                      lg: "0px",
                    },
                    marginLeft: {
                      xs: "-7vw",
                      sm: "-37px",
                      md: "11vw",
                      lg: "28vw",
                      xl: "27%",
                    },
                    width: {
                      xs: "96vw",
                      md: "83vw",
                      lg: "58vw",
                      xl: "67%",
                    },
                    height: {
                      xs: "87vw",
                      md: "75vw",
                      lg: "53vw",
                      xl: "680px",
                    },
                  }}
                  className="ihs-imgBottom"
                />
              </Grid> 
              */}
            </Grid>
          </MaxGridContainer>
        </Grid>
      </ProjectPage>
    </PageTemplate>
  );
};
export default IHS;
