import "./App.css";

import { HashRouter as Router, Routes, Route } from "react-router-dom";

// Mui
import { ThemeProvider } from "@mui/material/styles";

// App
import Home from "pages/home";
import Brytemap from "pages/brytemap";
import IHS from "pages/ihs";
import Twill from "pages/twill";
import Risewell from "pages/risewell";
import CharacterCo from "pages/characterCo";
import EPAM from "pages/epam";
import Giacometti from "pages/giacometti";
import Embroidery from "pages/embroidery";
import theme from "app/theme";
import Alliance from "pages/alliance";
import CVA from "pages/cva";
import Baizeh from "pages/baizeh";
import Canine from "pages/canine";
import Ryl from "pages/ryl";
import Bud from "pages/bud";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/brytemap" element={<Brytemap />} />
          <Route path="/ihs" element={<IHS />} />
          <Route path="/alliance" element={<Alliance />} />
          <Route path="/c-vantage" element={<CVA />} />
          <Route path="/baizeh" element={<Baizeh />} />
          <Route path="/canineComrades" element={<Canine />} />
          <Route path="/ryl" element={<Ryl />} />
          <Route path="/budTea" element={<Bud />} />
          <Route path="/twill" element={<Twill />} />
          <Route path="/risewell" element={<Risewell />} />
          <Route path="/characterCo" element={<CharacterCo />} />
          <Route path="/epam" element={<EPAM />} />
          <Route path="/giacometti" element={<Giacometti />} />
          <Route path="/whatsTheStitch" element={<Embroidery />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
