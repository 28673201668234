import React, { useState } from "react";
import { styled } from "styled-components";
import { Link } from "react-router-dom";

// Mui
import { Typography, Grid, Button } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

// App
import theme from "app/theme";

const gallerySpacing = 40;

const StyledGrid = styled(({ side, ...props }) => <Grid {...props} />)`
  display: none;
  border-right: ${(props) =>
    props.side === "topRight"
      ? `1px solid ${theme.palette.white.main}`
      : "none"};
  border-left: ${(props) =>
    props.side === "topLeft"
      ? `1px solid ${theme.palette.white.main}`
      : "none"};
  border-top: ${(props) =>
    props.side === "left" || props.side === "right"
      ? `1px solid ${theme.palette.white.main}`
      : "none"};
  width: ${(props) =>
    props.side === "left" || props.side === "right"
      ? `calc(100% + ${gallerySpacing}px)`
      : "100%"};
  ${theme.breakpoints.up("md")} {
    display: block;
  }
  ${theme.breakpoints.up("lg")} {
    width: ${(props) =>
      props.side === "left" || props.side === "right"
        ? `calc(100% + ${gallerySpacing * 1.5}px)`
        : "100%"};
  }
`;

// const getWidth = (width) => {
//   switch (width) {
//     case 6:
//       return `calc(25% + ${gallerySpacing}px)`;
//     case 3:
//       return `calc(100% + ${gallerySpacing}px)`;
//     default:
//       return "100%";
//   }
// };

const getMargin = (side) => {
  switch (side) {
    case "topRight":
    case "topLeft":
      return `0px !important`;
    case "left":
      return `0px -${gallerySpacing}px 0px 0px !important`;
    case "right":
      return `0px 0px 0px -${gallerySpacing}px !important`;
    default:
      return "0px";
  }
};

const getPadding = (side) => {
  switch (side) {
    case "topRight":
    case "topLeft":
      return "0px 15px 15px !important";
    case "left":
      return "10px 35px 0px 0px !important";
    case "right":
      return "10px 0px 0px 35px !important";
    default:
      return "0px";
  }
};

const getTextAlign = (side) => {
  switch (side) {
    case "right":
    case "topRight":
      return "right";
    default:
      return "left";
  }
};

const getGridClass = (side) => {
  switch (side) {
    case "right":
      return "gallery-grid-right";
    case "left":
      return "gallery-grid-left";
    case "topRight":
    case "topLeft":
      return "gallery-grid-top";
    default:
      return "";
  }
};

const Description = ({
  side,
  client,
  services,
  action,
  gridWidth,
  isVisible,
  to,
  hideLink,
  negMarginBottom,
  isSafari,
}) => {
  const isNewLink = to && to.includes("https");

  return (
    <StyledGrid
      side={side}
      className={
        !isSafari ? (isVisible ? `${getGridClass(side)}` : "hidden") : ""
      }
      sx={{
        textAlign: getTextAlign(side),
        margin: getMargin(side),
        padding: getPadding(side),
        marginBottom: negMarginBottom ? "-40px !important" : "0px",
      }}
    >
      {client && (
        <Typography variant="h3" paragraph color="textSecondary">
          {client}
        </Typography>
      )}

      {services.map((service) => (
        <Typography
          key={service}
          variant="body2"
          gutterBottom
          color="textSecondary"
        >
          {service}
        </Typography>
      ))}

      {!hideLink && (
        <Button
          variant="text"
          component={Link}
          to={to}
          target={isNewLink ? "_blank" : "_self"}
          endIcon={<KeyboardArrowRightIcon />}
        >
          {action}
        </Button>
      )}
    </StyledGrid>
  );
};
export default Description;
