import React from "react";

import Grid from "@mui/material/Grid";
import PageTemplate, { MaxGridContainer } from "components/PageTemplate";
import { Card, Typography } from "@mui/material";
import ProjectPage from "components/projectPage";
import styled from "styled-components";
import Image from "mui-image";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "app/theme";

const LogoImg = styled.img`
  margin: 20px;
  width: 80%;
  max-width: 230px;
  ${theme.breakpoints.up("sm")} {
    margin: 40px 20px;
    margin-bottom: 0px;
    width: 35%;
  }
  ${theme.breakpoints.up("md")} {
    width: 20%;
  }
`;

const SmallImgCard = styled(Card)`
  margin-top: 20px;
`;

const smallImages = [
  "customer.jpg",
  "cart.jpg",
  "payment.jpg",
  "users.jpg",
  "week.jpg",
  "sales.jpg",
  "productDetails.jpg",
  "queueList.jpg",
];

const blurb = (
  <>
    <p>
      At Brytemap, I was the sole designer and one of three developers. I led
      the redesign of the company’s flagship retail ecosystem software, greatly
      improving customer retention and doubling the client base within the first
      year.
    </p>
    <p>
      My other responsibilities included conducting user research and product
      usability testing, expanding and establishing the company's visual brand
      through software UI and marketing deliverables, rapidly producing
      deliverables including user flows, prototypes, final designs, style
      guides, usability testing reports, and instructional documents, learning
      React.js, Android XML, and Kotlin to code the front-end of our
      applications, managing tasks and timelines for the development team, and
      assisting the marketing director with the website and social media
      graphics.
    </p>
  </>
);

const Brytemap = () => {
  const isUpSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <PageTemplate>
      <ProjectPage
        title="Brytemap"
        blurb={blurb}
        jobTitle="Lead UI/UX Designer, Front-End Developer"
      >
        <Grid>
          <MaxGridContainer>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Typography variant="h3" textAlign="center" gutterBottom>
                  Company branding
                </Typography>

                <Grid
                  item
                  xs={12}
                  sx={{ textAlign: "center", marginTop: "40px" }}
                >
                  <img
                    src="/img/brytemap/logos/Brytemap.png"
                    alt="Brytemap"
                    width="60px"
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "5px" }}>
                  <Typography variant="body2" textAlign="center" paragraph>
                    Existing company logo
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    marginTop: "50px",
                    textAlign: "center",
                    backgroundColor: "#fff",
                    padding: "50px 22px;",
                    borderRadius: "10px",
                  }}
                >
                  <Typography textAlign="center" variant="body2">
                    New logos for each of Brytemap’s services in keeping with
                    the company’s original brand
                  </Typography>
                  <LogoImg src="/img/brytemap/logos/scout.png" />
                  <LogoImg src="/img/brytemap/logos/grow.png" />
                  <LogoImg src="/img/brytemap/logos/sale.png" />
                  <LogoImg src="/img/brytemap/logos/support.png" />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ marginTop: "60px", marginBottom: "-10px" }}
              >
                <Typography variant="h3" textAlign="center" gutterBottom>
                  Brytemap Grow
                </Typography>
                <Typography textAlign="center" variant="body2">
                  Designed and developed the Android app used to manage
                  inventory of grow facilities
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img
                  src="/img/brytemap/scout/scout.gif"
                  alt="Scout"
                  width="100%"
                />
              </Grid>
            </Grid>
          </MaxGridContainer>

          <MaxGridContainer>
            <Grid container spacing={6} sx={{ paddingBottom: "80px" }}>
              <Grid
                item
                xs={12}
                sx={{ marginTop: "80px", marginBottom: "-10px" }}
              >
                <Typography variant="h3" textAlign="center" gutterBottom>
                  Brytemap Sale
                </Typography>
                <Typography
                  textAlign="center"
                  variant="body2"
                  sx={{ maxWidth: "700px", margin: "0 auto" }}
                >
                  Redesigned the entire 25+ page web application used as a
                  point-of-sale and business management tool, focusing on
                  user-friendly design, efficient information scanning,
                  consistent elements, scalability, and research-based
                  development
                </Typography>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <img
                      src="/img/brytemap/greenr/customer.jpg"
                      width="100%"
                      className="shadow"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <img
                      src="/img/brytemap/greenr/cart.jpg"
                      width="100%"
                      className="shadow"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <img
                      src="/img/brytemap/greenr/payment.jpg"
                      width="100%"
                      className="shadow"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={6}>
                <img
                  src="/img/brytemap/greenr/sales.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <img
                  src="/img/brytemap/greenr/queue.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <img
                  src="/img/brytemap/greenr/product.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <img
                  src="/img/brytemap/greenr/drawer.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <img
                  src="/img/brytemap/greenr/more.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <img
                  src="/img/brytemap/greenr/week.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <img
                  src="/img/brytemap/greenr/users.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <img
                  src="/img/brytemap/greenr/receipt.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <img
                  src="/img/brytemap/greenr/settings.jpg"
                  width="100%"
                  className="shadow"
                />
              </Grid>
            </Grid>
          </MaxGridContainer>

          {/* {isUpSm ? (
            <Image
              src="/img/brytemap/greenr/greenr.png"
              duration={0}
              sx={{
                width: {
                  xs: "10vw !important",
                  sm: "calc(180vw - 20px) !important",
                  md: "calc(180vw - 20px) !important",
                  lg: "calc(150vw - 20px) !important",
                  xl: "1850px !important",
                },
                margin: "25px auto",
                marginLeft: {
                  xs: "0vw !important",
                  sm: "-40vw !important",
                  md: "-40vw !important",
                  lg: "-25vw !important",
                  xl: "calc((100vw - 1850px) / 2) !important",
                },
              }}
            />
          ) : (
            <Grid sx={{ width: "calc(100% - 80px)", margin: "50px auto" }}>
              {smallImages.map((imgName) => (
                <SmallImgCard elevation={6}>
                  <Image
                    src={"/img/brytemap/greenr/" + imgName}
                    duration={0}
                    width="100%"
                  />
                </SmallImgCard>
              ))}
            </Grid>
          )} */}
        </Grid>
      </ProjectPage>
    </PageTemplate>
  );
};
export default Brytemap;
