import React from "react";

// Mui
import { Grid, IconButton, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { Link } from "react-router-dom";
import { MaxGridContainer } from "./PageTemplate";
import theme from "app/theme";

const Nav = ({ isHome }) => (
  <MaxGridContainer>
    <Grid
      container
      sx={{ padding: "30px 0px" }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Link to="/">
        <img
          src={isHome ? "/img/whiteLogoH.png" : "/img/blackLogoH.png"}
          alt="Jaclyn Davis Design"
          height="40px"
        />
      </Link>

      <Grid
        style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}
      >
        <Typography
          sx={{
            fontSize: "1rem !important",
            color: isHome ? theme.palette.white.main : theme.palette.black.main,
            padding: "20px",
          }}
        >
          jaclyndavisdesign@gmail.com
        </Typography>

        <Tooltip
          title="Download resume"
          arrow
          TransitionComponent={Fade}
          enterDelay={1000}
        >
          <IconButton>
            <a
              href="/files/JaclynDavis_Resume.pdf"
              download
              style={{
                height: "30px",
                width: "30px",
                padding: "8px 6px 8px 10px",
              }}
            >
              <img
                src={
                  isHome
                    ? "/img/file-download-alt.svg"
                    : "/img/file-download-alt-black.svg"
                }
                alt="download"
                height="30px"
              />
            </a>
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  </MaxGridContainer>
);

export default Nav;
