import PageTemplate from "components/PageTemplate";
import React from "react";

const CharacterCo = () => {
  return (
    <PageTemplate isCharacterCo>
      <iframe
        src="/CharacterCo/index.html"
        title="Character Co."
        style={{ border: "none", width: "100vw", height: "100vh" }}
      ></iframe>
    </PageTemplate>
  );
};
export default CharacterCo;
