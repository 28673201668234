import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// External
import { gsap, Power2 } from "gsap";
import { styled } from "styled-components";

// Mui
import Grid from "@mui/material/Grid";
import { Button, Collapse, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// App
import PageTemplate, { MaxGridContainer } from "components/PageTemplate";
import theme from "app/theme";
import TextSection from "components/textSection";
import Description from "components/description";
import { Link } from "react-router-dom";

const GSAPTypography = styled(
  ({ font, sizeLg, sizeSm, lineHeight, ...props }) => <Typography {...props} />
)`
  position: absolute;
  bottom: 0px;
  left: 0px;
  white-space: nowrap;
  will-change: transform, opacity;
  font-family: ${(props) => props.font} !important;
  font-size: ${(props) => props.sizeSm}px !important;
  line-height: ${(props) => props.lineHeight} !important;
  ${theme.breakpoints.up("sm")} {
    font-size: ${(props) => props.sizeSm * 1.3}px !important;
  }
  ${theme.breakpoints.up("md")} {
    font-size: ${(props) => props.sizeLg * 0.9}px !important;
  }
  ${theme.breakpoints.up("lg")} {
    font-size: ${(props) => props.sizeLg}px !important;
  }
`;

const HideSmGrid = styled(Grid)`
  display: block;
  ${theme.breakpoints.up("sm")} {
    display: none;
  }
`;

export const ShowSmGrid = styled(Grid)`
  display: none;
  ${theme.breakpoints.up("sm")} {
    display: block;
  }
`;
export const ShowMdGrid = styled(Grid)`
  display: none;
  ${theme.breakpoints.up("md")} {
    display: block;
  }
`;
export const ShowLgGrid = styled(Grid)`
  display: none;
  ${theme.breakpoints.up("lg")} {
    display: block;
  }
`;

const services = [
  { class: "ui", text: "UI/UX", font: "Roboto", sizeLg: 135, sizeSm: 90 },
  {
    class: "branding",
    text: "branding",
    font: "beastly",
    weight: 100,
    sizeLg: 120,
    sizeSm: 62,
  },
  {
    class: "web",
    text: "web design",
    font: "open sans",
    weight: 300,
    sizeLg: 118,
    sizeSm: 60,
  },
  {
    class: "motion",
    text: " motion graphics",
    font: "lobster, cursive",
    weight: 400,
    sizeLg: 110,
    sizeSm: 48,
  },
  {
    class: "dev",
    text: "front-end dev",
    font: "roboto mono",
    weight: 400,
    sizeLg: 90,
    sizeSm: 39,
  },
];

const Home = () => {
  const isUpSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const isUpLg = useMediaQuery(theme.breakpoints.up("lg"));

  const [isMoreVisible, setIsMoreVisible] = useState(false);

  // Check for Safari
  const userAgent = navigator.userAgent;
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  // ================ SERVICES ================
  const timelineRef = useRef(null);

  useEffect(() => {
    const getLineWidth = (lg, sm) => {
      if (isUpLg) {
        return `${lg}`;
      } else if (isUpMd) {
        return `${lg * 0.9}`;
      } else if (isUpSm) {
        return `${sm}`;
      } else {
        return "100%";
      }
    };

    const timeline = gsap.timeline({ repeat: -1 }); // Infinite repeat

    // position elements
    gsap.set("#underline", { scaleX: 0.42 });
    gsap.set([".ui", ".branding", ".web", ".dev"], {
      transform: "translateY(150px)",
      opacity: 0,
    });
    gsap.set(".motion", {
      transform: "translateX(6px) translateY(150px)",
    });

    // Define animations for each element
    const inValues = { duration: 0.8, ease: Power2.easeOut, opacity: 1 };
    const outValues = { duration: 0.8, ease: Power2.easeIn };
    const lineValues = { duration: 0.3, ease: Power2.eastInOut };

    timeline
      // UI/UX
      .to(".ui", { transform: "translateY(-18px)", ...inValues }, "-=0.7")
      .to(".ui", { transform: "translateY(140px)", ...outValues })

      //   Branding
      .to(
        "#underline",
        { scaleX: getLineWidth(0.7, 0.7), ...lineValues },
        "-=0.1"
      )
      .to(".branding", { transform: "translateY(-23px)", ...inValues }, "-=0.3")
      .to(".branding", { transform: "translateY(150px)", ...outValues })

      // Web design
      .to(
        "#underline",
        { scaleX: getLineWidth(0.72, 0.72), ...lineValues },
        "-=0.1"
      )
      .to(".web", { transform: "translateY(-27px)", ...inValues }, "-=0.3")
      .to(".web", { transform: "translateY(150px)", ...outValues })

      // motion graphics
      .to(
        "#underline",
        { scaleX: getLineWidth(0.85, 0.85), ...lineValues },
        "-=0.1"
      )
      .to(
        ".motion",
        { transform: "translateX(6px) translateY(-25px)", ...inValues },
        "-=0.3"
      )
      .to(".motion", {
        transform: "translateX(6px) translateY(150px)",
        ...outValues,
      })

      // front-end dev
      .to(
        "#underline",
        { scaleX: getLineWidth(0.86, 0.86), ...lineValues },
        "-=0.1"
      )
      .to(".dev", { transform: "translateY(-28px)", ...inValues }, "-=0.3")
      .to(".dev", { transform: "translateY(150px)", ...outValues })

      // set back to UI/UX width
      .to(
        "#underline",
        { scaleX: getLineWidth(0.42, 0.42), ...lineValues },
        "-=0.3"
      );

    // Store the timeline in the ref for later control
    timelineRef.current = timeline;

    // Clean up the animation on unmount
    return () => timeline.kill();
  }, [isUpSm, isUpMd, isUpLg]);

  // ================ ANIMATIONS ON SCROLL ================
  const { ref: servicesRef, inView: isServicesVisible } = useInView({
    triggerOnce: true,
  });
  const { ref: textRef1, inView: isTextRef1Visible } = useInView({
    triggerOnce: true,
    threshold: 0.15,
  });
  const { ref: textRef2, inView: isTextRef2Visible } = useInView({
    triggerOnce: true,
    threshold: 0.15,
  });
  const { ref: brytemapRef, inView: isBrytemapVisible } = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });
  const { ref: allianceRef, inView: isAllianceVisible } = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });
  const { ref: twillRef, inView: isTwillVisible } = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });
  const { ref: risewellRef, inView: isRisewellVisible } = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });
  const { ref: IHSRef, inView: isIHSVisible } = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });
  const { ref: characterRef, inView: isCharacterVisible } = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });
  const { ref: whartonRef, inView: isWhartonVisible } = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });
  const { ref: CVARef, inView: isCVAVisible } = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });
  const { ref: EPAMRef, inView: isEPAMVisible } = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });
  const { ref: budTeaRef, inView: isBudTeaVisible } = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });
  const { ref: rylRef, inView: isRylVisible } = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });
  const { ref: canineRef, inView: isCanineVisible } = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });
  const { ref: baizehRef, inView: isBaiZehVisible } = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });

  const containerHeight = { sm: "calc(100% + 16px)", md: "calc(100% + 40px)" };

  const getRylSrc = isUpSm ? "img/gallery/ryl.jpg" : "img/gallery/rylSmall.jpg";

  return (
    <PageTemplate isHome>
      <MaxGridContainer>
        <Grid
          ref={servicesRef}
          className={
            !isSafari ? (isServicesVisible ? "animateP1" : "hidden") : ""
          }
          id="timeline-container"
          sx={{
            height: { xs: "120px", sm: "175px", md: "230px", lg: "250px" },
            position: "relative",
            overflow: "hidden",
            display: "flex",
            alignItems: "flex-end",
            width: "900px",
          }}
        >
          <Grid
            id="underline"
            sx={{
              height: "3px",
              backgroundColor: theme.palette.white.main,
              width: "800px",
              transformOrigin: "left center",
            }}
          ></Grid>
          {services.map((service) => (
            <GSAPTypography
              color="textSecondary"
              variant="h1"
              key={service.class}
              className={service.class}
              font={service.font}
              sizeSm={service.sizeSm}
              sizeLg={service.sizeLg}
              lineHeight={service.lineHeight ? service.lineHeight : 1}
            >
              {service.text}
            </GSAPTypography>
          ))}
        </Grid>

        <TextSection
          isSafari={isSafari}
          textRef1={textRef1}
          textRef2={textRef2}
          isTextRef1Visible={isTextRef1Visible}
          isTextRef2Visible={isTextRef2Visible}
        />

        {/* ============ GALLERY ============ */}
        <Grid
          container
          sx={{ marginTop: "90px", paddingBottom: "100px" }}
          className="gallery-container"
        >
          {/* ============================ BRYTEMAP & ALLIANCE ============================ */}
          <Grid item xs={12} sm={8} md={6} className="galleryItem">
            <Link to="/brytemap">
              <div ref={brytemapRef} width={540} height={540}>
                <LazyLoadImage
                  src="/img/gallery/brytemap.gif"
                  alt="Brytemap"
                  width="100%"
                  className={
                    !isSafari
                      ? isBrytemapVisible
                        ? "animateImage1"
                        : "hidden"
                      : ""
                  }
                  effect="blur"
                  visible={isBrytemapVisible}
                />
              </div>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={6}
            className="galleryItem"
            display="flex"
            flexDirection={{ xs: "row", sm: "column" }}
            justifyContent={{ xs: "flex-end", sm: "space-between" }}
          >
            <Grid container className="gallery-container">
              <Grid item xs={6} lg={3} className="galleryItem">
                <Description
                  isSafari={isSafari}
                  client="Brytemap"
                  services={[
                    "UI/UX design",
                    "Front-end dev",
                    "Brand strategy",
                    "Project management",
                  ]}
                  action="DETAILS"
                  side="right"
                  to="/brytemap"
                  isVisible={isBrytemapVisible}
                />
              </Grid>
              <Grid item xs={6} lg={9} className="galleryItem"></Grid>
            </Grid>

            <Grid container className="gallery-container">
              <Grid item xs={12} sm={12} md={6} className="galleryItem">
                <Link to="/alliance">
                  <div ref={allianceRef} width={540} height={540}>
                    <LazyLoadImage
                      src="/img/gallery/alliance.jpg"
                      alt="Alliance"
                      width="100%"
                      className={
                        !isSafari
                          ? isAllianceVisible
                            ? "animateImage"
                            : "hidden"
                          : ""
                      }
                      effect="blur"
                      visible={isAllianceVisible}
                    />
                  </div>
                </Link>
              </Grid>
              <ShowMdGrid item xs={6} lg={3} className="galleryItem">
                <Description
                  isSafari={isSafari}
                  client="Alliance Primary Health"
                  services={["Visual branding"]}
                  action="DETAILS"
                  to="/alliance"
                  side="right"
                  isVisible={isAllianceVisible}
                />
              </ShowMdGrid>
            </Grid>
          </Grid>

          {/* ============================ IHS & CVA ============================ */}
          <ShowLgGrid item md={1.5} className="galleryItem"></ShowLgGrid>
          <ShowMdGrid item xs={3} lg={1.5} className="galleryItem">
            <Description
              isSafari={isSafari}
              client="IHS"
              alt="IHS"
              services={["UI/UX", "Front-end dev", "Project management"]}
              action="DETAILS"
              side="left"
              isVisible={isIHSVisible}
              to="/IHS"
            />
          </ShowMdGrid>
          <Grid item xs={12} sm={8} md={6} className="galleryItem">
            <Link to="/IHS">
              <div ref={IHSRef} width={540} height={540}>
                <LazyLoadImage
                  src="img/gallery/IHS.jpg"
                  alt="IHS"
                  width="100%"
                  className={
                    !isSafari ? (isIHSVisible ? "animateImage" : "hidden") : ""
                  }
                  effect="blur"
                  visible={isIHSVisible}
                />
              </div>
            </Link>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            md={3}
            className="galleryItem"
            sx={{
              display: "flex",
              justifyContent: { xs: "flex=start", md: "flex-end" },
              flexDirection: "column",
            }}
          >
            <Description
              isSafari={isSafari}
              client="C-Vantage Advisors"
              services={["Visual branding"]}
              action="DETAILS"
              side="topRight"
              isVisible={isCVAVisible}
              hideLink
            />
            <div ref={CVARef} width={250} height={250}>
              <LazyLoadImage
                src="img/gallery/CVA.gif"
                alt="CVA"
                width="100%"
                className={
                  !isSafari ? (isCVAVisible ? "animateImage" : "hidden") : ""
                }
                effect="blur"
                visible={isCVAVisible}
              />
            </div>
          </Grid>

          {/* ============================ CANINE & BAI'ZEH ============================ */}
          <Grid item xs={12} sm={4} md={6} className="galleryItem">
            <Grid
              container
              className="gallery-container"
              sx={{ height: containerHeight }}
            >
              <ShowMdGrid item xs={6} md={12} className="galleryItem">
                <Grid container className="gallery-container">
                  <Grid item xs={6} lg={8.5} className="galleryItem"></Grid>
                  <Grid item xs={6} lg={3.5} className="galleryItem">
                    <Description
                      isSafari={isSafari}
                      client="Bai'Zeh Consulting"
                      alt="Bai'Zeh Consulting"
                      services={["Visual branding", "Web design & dev"]}
                      action="WEBSITE"
                      side="left"
                      isVisible={isBaiZehVisible}
                      to="/baizehConsulting"
                      hideLink
                    />
                  </Grid>
                </Grid>
              </ShowMdGrid>
              <Grid
                item
                xs={12}
                className="galleryItem"
                display="flex"
                alignItems="flex-end"
              >
                <Grid container className="gallery-container">
                  <ShowLgGrid item xs={0} lg={3} className="galleryItem" />

                  <Grid item xs={6} lg={3} className="galleryItem">
                    <Description
                      isSafari={isSafari}
                      client="Canine Comrades"
                      services={["Visual branding"]}
                      action="DETAILS"
                      side="left"
                      isVisible={isCanineVisible}
                      hideLink
                    />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6} className="galleryItem">
                    <div ref={canineRef} width={250} height={250}>
                      <LazyLoadImage
                        src="img/gallery/canine.png"
                        alt="Canine Comrades logo"
                        width="100%"
                        className={
                          !isSafari
                            ? isCanineVisible
                              ? "animateImage"
                              : "hidden"
                            : ""
                        }
                        effect="blur"
                        visible={isCanineVisible}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={8} md={6} className="galleryItem">
            {/* <Link to="/baizehConsulting"> */}
            <div ref={baizehRef} width={540} height={540}>
              <LazyLoadImage
                src="img/gallery/baizeh.jpg"
                alt="Wharton"
                width="100%"
                className={
                  !isSafari ? (isBaiZehVisible ? "animateImage" : "hidden") : ""
                }
                effect="blur"
                visible={isBaiZehVisible}
              />
            </div>
            {/* </Link> */}
          </Grid>

          {/* ============================ RYL & CHARACTER CO ============================ */}
          <Grid item xs={12} sm={8} md={6} className="galleryItem">
            {/* <Link to="/rylCo"> */}
            <div ref={rylRef} width={540} height={395}>
              <LazyLoadImage
                src={getRylSrc}
                alt="Ryl Tea packaging"
                width="100%"
                className={
                  !isSafari ? (isRylVisible ? "animateImage" : "hidden") : ""
                }
                effect="blur"
                visible={isRylVisible}
              />
            </div>
            {/* </Link> */}
          </Grid>
          <Grid item xs={12} sm={4} md={6} className="galleryItem">
            <Grid
              container
              className="gallery-container"
              sx={{ height: containerHeight }}
            >
              <ShowMdGrid item xs={12} className="galleryItem">
                <Grid container className="gallery-container">
                  <Grid item xs={6} lg={3} className="galleryItem">
                    <Description
                      isSafari={isSafari}
                      client="The Ryl Co."
                      services={["Branding", "Packaging"]}
                      action="DETAILS"
                      side="right"
                      isVisible={isRylVisible}
                      negMarginBottom
                      to="/rylCo"
                      hideLink
                    />
                  </Grid>
                  <Grid item xs={6} sm={9}></Grid>
                </Grid>
              </ShowMdGrid>
              <Grid
                item
                xs={12}
                className="galleryItem"
                display="flex"
                alignItems="flex-end"
              >
                <Grid container className="gallery-container">
                  <Grid item xs={6} sm={12} md={6} className="galleryItem">
                    <Link to="/characterCo">
                      <div ref={characterRef} width={250} height={250}>
                        <LazyLoadImage
                          src="img/gallery/character.jpg"
                          alt="Character Co."
                          width="100%"
                          className={
                            !isSafari
                              ? isCharacterVisible
                                ? "animateImage"
                                : "hidden"
                              : ""
                          }
                          effect="blur"
                          visible={isCharacterVisible}
                        />
                      </div>
                    </Link>
                  </Grid>
                  <ShowMdGrid item xs={6} lg={3} className="galleryItem">
                    <Description
                      isSafari={isSafari}
                      client="Character Co."
                      services={[
                        "Branding",
                        "Typography",
                        "Web design & dev",
                        "Motion graphics",
                      ]}
                      action="WEBSITE"
                      side="right"
                      isVisible={isCharacterVisible}
                      to="/characterco"
                    />
                  </ShowMdGrid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* ============================ TWILL ============================ */}
          <ShowSmGrid
            item
            sm={4}
            md={3}
            lg={4.5}
            className="galleryItem"
          ></ShowSmGrid>
          <ShowMdGrid item sm={0} md={3} lg={1.5} className="galleryItem">
            <Description
              isSafari={isSafari}
              client="Twill"
              services={[
                "Social media & in-app graphics",
                "Illustration",
                "Motion graphics",
              ]}
              action="DETAILS"
              side="left"
              isVisible={isTwillVisible}
              to="/twill"
            />
          </ShowMdGrid>
          <Grid item sm={8} md={6} className="galleryItem">
            <Link to="/twill">
              <div ref={twillRef} width={540} height={250}>
                <LazyLoadImage
                  src="/img/gallery/twill.jpg"
                  alt="Twill"
                  width="100%"
                  className={
                    !isSafari
                      ? isTwillVisible
                        ? "animateImage"
                        : "hidden"
                      : ""
                  }
                  effect="blur"
                  visible={isTwillVisible}
                />
              </div>
            </Link>
          </Grid>

          {/* ============================ RISEWELL & BUD TEA ============================ */}
          <Grid item xs={12} sm={8} md={6} className="galleryItem">
            <Link to="/risewell">
              <div ref={risewellRef} width={540} height={540}>
                <LazyLoadImage
                  src="/img/gallery/risewell.jpg"
                  alt="Risewell"
                  width="100%"
                  className={
                    !isSafari
                      ? isRisewellVisible
                        ? "animateImage"
                        : "hidden"
                      : ""
                  }
                  effect="blur"
                  visible={isRisewellVisible}
                />
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4} md={6} className="galleryItem">
            <Grid
              container
              className="gallery-container"
              sx={{ height: containerHeight }}
            >
              <ShowMdGrid item xs={12} className="galleryItem">
                <Grid container className="gallery-container">
                  <Grid item xs={6} lg={3} className="galleryItem">
                    <Description
                      isSafari={isSafari}
                      client="Risewell"
                      alt="Risewell"
                      services={[
                        "Social media & email campaigns",
                        "Product photogrpahy",
                      ]}
                      action="DETAILS"
                      side="right"
                      isVisible={isRisewellVisible}
                      to="/risewell"
                    />
                  </Grid>
                  <Grid item xs={6} sm={9}></Grid>
                </Grid>
              </ShowMdGrid>
              <Grid
                item
                xs={12}
                className="galleryItem"
                display="flex"
                alignItems="flex-end"
              >
                <Grid container className="gallery-container">
                  <Grid item xs={6} sm={12} md={6} className="galleryItem">
                    {/* <Link to="/budtea"> */}
                    <div ref={budTeaRef} width={395} height={395}>
                      <LazyLoadImage
                        src="img/gallery/budTea.jpg"
                        alt="Can design for Bud Tea"
                        width="100%"
                        className={
                          !isSafari
                            ? isBudTeaVisible
                              ? "animateImage"
                              : "hidden"
                            : ""
                        }
                        effect="blur"
                        visible={isBudTeaVisible}
                      />
                    </div>
                    {/* </Link> */}
                  </Grid>
                  <ShowMdGrid item xs={6} lg={3} className="galleryItem">
                    <Description
                      isSafari={isSafari}
                      client="Bud Tea"
                      services={["Branding", "Packaging"]}
                      side="right"
                      isVisible={isBudTeaVisible}
                      hideLink
                    />
                  </ShowMdGrid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* ============================ WHARTON & EPAM ============================ */}
          <Grid item xs={12} sm={4} md={6} className="galleryItem">
            <Grid
              container
              className="gallery-container"
              sx={{ height: containerHeight }}
            >
              <ShowMdGrid item xs={6} md={12} className="galleryItem">
                <Grid container className="gallery-container">
                  <Grid item xs={6} lg={8.5} className="galleryItem"></Grid>
                  <Grid item xs={6} lg={3.5} className="galleryItem">
                    <Description
                      isSafari={isSafari}
                      client="EPAM"
                      services={["Motion graphics"]}
                      action="REEL"
                      side="left"
                      isVisible={isEPAMVisible}
                      to="/epam"
                    />
                  </Grid>
                </Grid>
              </ShowMdGrid>
              <Grid
                item
                xs={12}
                className="galleryItem"
                display="flex"
                alignItems="flex-end"
              >
                <Grid container className="gallery-container">
                  <ShowLgGrid item xs={0} lg={3} className="galleryItem" />

                  <Grid item xs={6} lg={3} className="galleryItem">
                    <Description
                      isSafari={isSafari}
                      client="Wharton Energy Club"
                      services={["Web design & dev"]}
                      action="WEBSITE"
                      side="left"
                      isVisible={isWhartonVisible}
                      to="https://whartonenergyclub.org/"
                    />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6} className="galleryItem">
                    <div ref={whartonRef} width={250} height={250}>
                      <a href="https://whartonenergyclub.org/" target="_bank">
                        <LazyLoadImage
                          src="img/gallery/wharton.jpg"
                          alt="Wharton Energy Club"
                          width="100%"
                          className={
                            !isSafari
                              ? isWhartonVisible
                                ? "animateImage"
                                : "hidden"
                              : ""
                          }
                          effect="blur"
                          visible={isWhartonVisible}
                        />
                      </a>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={8} md={6} className="galleryItem">
            <Link to="/epam">
              <div ref={EPAMRef} width={540} height={540}>
                <LazyLoadImage
                  src="img/gallery/EPAM.gif"
                  alt="EPAM"
                  width="100%"
                  className={
                    !isSafari ? (isEPAMVisible ? "animateImage" : "hidden") : ""
                  }
                  effect="blur"
                  visible={isEPAMVisible}
                />
              </div>
            </Link>
          </Grid>
        </Grid>

        {/* ============== CREDITS ============== */}
        <Grid>
          <Typography
            variant="body1"
            textAlign="center"
            sx={{
              color: "rgba(255,255,255,0.5)",
              paddingBottom: "10px",
              fontSize: "0.875rem !important",
            }}
          >
            This site was designed, developed, animated, and deployed by Jaclyn
            &nbsp; ◡̈ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#169; 2024
          </Typography>
        </Grid>
      </MaxGridContainer>
    </PageTemplate>
  );
};

export default Home;
