import React from "react";
import { styled } from "styled-components";

// Mui
import { Typography, Grid } from "@mui/material";

// App
import theme from "app/theme";

const TextSectionContainer = styled(Grid)`
  ${theme.breakpoints.up("lg")} {
    justify-content: space-around;
    margin-top: 120px !important;
  }
  ${theme.breakpoints.up("xl")} {
    justify-content: space-between;
    margin-top: 120px !important;
  }
`;

const ProfessionGrid = styled(Grid)`
  margin-top: 60px !important;
  width: 100%;
  max-width: 550px;
  ${theme.breakpoints.up("md")} {
    margin-top: 70px !important;
  }
  ${theme.breakpoints.up("md")} {
    margin-left: 10vw !important;
  }
  ${theme.breakpoints.up("lg")} {
    margin-top: 0px !important;
    width: auto;
    max-width: 610px;
    margin-left: 9vw !important;
  }
`;

const FavoritesGrid = styled(Grid)`
  margin-top: 50px !important;
  margin-left: 20vw !important;
  ${theme.breakpoints.up("md")} {
    margin-top: 70px !important;
  }
  ${theme.breakpoints.up("sm")} {
    margin-left: 40vw !important;
  }
  ${theme.breakpoints.up("lg")} {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
`;

const ParagraphsContainer = styled(Grid)`
  margin-left: 20px !important;
  flex-grow: 1;
  ${theme.breakpoints.up("md")} {
    margin-left: 30px !important;
  }
`;

const profession = ["N", "O", "I", "S", "S", "E", "F", "O", "R", "P"];
const favorites = ["S", "E", "T", "I", "R", "O", "V", "A", "F"];

const favesArray = [
  { key: "Font", value: "Mrs Eaves" },
  { key: "Game", value: "Cities Skylines" },
  { key: "Restaurant", value: "Tomo Sushi & Ramen" },
  { key: "Ice cream flavor", value: "Mint chip" },
  { key: "Coffee beverage", value: "Dirty chai" },
  { key: "Sport", value: "Skiing" },
];

const VerticalWord = ({ wordArray }) => (
  <Grid>
    {wordArray.map((letter, index) => (
      <Typography
        key={letter + index}
        color="textSecondary"
        variant="h5"
        style={{
          transformOrigin: "50% 50%",
          transform: "rotate(-90deg)",
        }}
      >
        {letter}
      </Typography>
    ))}
  </Grid>
);

const TextSection = ({
  isSafari,
  textRef1,
  textRef2,
  isTextRef1Visible,
  isTextRef2Visible,
}) => {
  return (
    <TextSectionContainer container>
      <ProfessionGrid
        item
        ref={textRef1}
        className={
          !isSafari ? (isTextRef1Visible ? "animateP1" : "hidden") : ""
        }
      >
        <Grid
          container
          alignItems="stretch"
          flexDirection="horizontal"
          sx={{ flexWrap: "nowrap" }}
        >
          <VerticalWord wordArray={profession} />
          <ParagraphsContainer>
            <Typography variant="h4" gutterBottom color="textSecondary">
              Multi-disciplinary creative with 6+ years of experience,
              specializing in visual communication, UI/UX, vector illustration,
              motion graphics, and branding.
            </Typography>
            <Typography variant="h4" gutterBottom color="textSecondary">
              Passionate leader and ambitious learner with strong
              problem-solving, communication, time-management, and organization
              skills
            </Typography>
            <Typography variant="h4" color="textSecondary">
              Focused on creating effortless and enjoyable experiences through
              intriguing, intuitive, and innovative design
            </Typography>
          </ParagraphsContainer>
        </Grid>
      </ProfessionGrid>

      <FavoritesGrid
        item
        ref={textRef2}
        className={
          !isSafari ? (isTextRef2Visible ? "animateP2" : "hidden") : ""
        }
      >
        <Grid
          container
          alignItems="stretch"
          flexDirection="horizontal"
          sx={{ maxWidth: "620px", flexWrap: "nowrap" }}
        >
          <VerticalWord wordArray={favorites} />
          <ParagraphsContainer>
            {favesArray.map((item) => (
              <Typography variant="h4" key={item.key} color="textSecondary">
                <Typography
                  variant="body1"
                  display="inline"
                  color="textSecondary"
                >
                  {item.key}:
                </Typography>
                {" " + item.value}
              </Typography>
            ))}
          </ParagraphsContainer>
        </Grid>
      </FavoritesGrid>
    </TextSectionContainer>
  );
};
export default TextSection;
