// Mui
import { createTheme } from "@mui/material/styles";

const colors = {
  primary: {
    main: "#31A7AA",
  },
  secondary: {
    main: "#31A7AA",
  },
  white: {
    light: "#F3F2F1",
    main: "#F2E3DA",
  },
  black: {
    main: "#1B252D",
  },
  navy: {
    main: "#1F313C",
  },
  text: {
    primary: "#1B252D",
    secondary: "#F2E3DA",
  },
};

const MrEaves = "mr-eaves-xl-sans, open-sans, sans-serif";

const breakpointsTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1080,
      xl: 1220,
    },
  },
});

const theme = createTheme(breakpointsTheme, {
  palette: colors,

  typography: {
    h1: {
      fontSize: "3rem",
      fontFamily: MrEaves,
      color: colors.text.primary,
      lineHeight: 0.95,
    },
    h2: {
      fontSize: "2rem",
      fontFamily: MrEaves,
      color: colors.text.primary,
    },
    h3: {
      fontSize: "1.25rem",
      fontFamily: MrEaves,
      fontWeight: 700,
      color: colors.text.primary,
    },
    h4: {
      fontSize: "1.0675rem",
      fontFamily: MrEaves,
      fontWeight: 300,
      color: colors.text.primary,
      lineHeight: "1.15 !important",
      letterSpacing: "0.25px",
      [breakpointsTheme.breakpoints.up("md")]: {
        fontSize: "1.12rem",
      },
      [breakpointsTheme.breakpoints.up("lg")]: {
        fontSize: "1.2rem",
      },
    },
    // petite caps
    h5: {
      fontSize: "1rem",
      fontFamily: "mrs-eaves-roman-petite-caps, serif",
      letterSpacing: "0.25rem !important",
      lineHeight: 0.8,
      color: colors.text.primary,
      [breakpointsTheme.breakpoints.up("md")]: {
        fontSize: "1.06rem",
      },
      [breakpointsTheme.breakpoints.up("lg")]: {
        fontSize: "1.13rem",
      },
    },
    // italic Mrs Eaves
    h6: {
      fontSize: "1.125rem",
      fontFamily: "mrs-eaves-xl-serif, serif",
      fontStyle: "italic",
      [breakpointsTheme.breakpoints.up("md")]: {
        fontSize: "1.25rem",
      },
    },

    // sans-serif Mr Eaves
    body1: {
      fontSize: "1rem",
      fontFamily: MrEaves,
      color: colors.text.primary,
      lineHeight: "1.125 !important",
      [breakpointsTheme.breakpoints.up("lg")]: {
        fontSize: "1.125rem",
      },
    },
    // serif Mrs Eaves
    body2: {
      fontSize: "0.95rem",
      letterSpacing: 0,
      fontWeight: 400,
      fontFamily: "mrs-eaves-xl-serif, serif",
      color: colors.text.primary,
      lineHeight: "1.125 !important",
      [breakpointsTheme.breakpoints.up("md")]: {
        fontSize: "1.0875",
      },
      [breakpointsTheme.breakpoints.up("lg")]: {
        fontSize: "1.125",
      },
    },
  },

  // ------------- COMPONENTS -------------
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: MrEaves,
          minWidth: "45px",
          color: colors.white.main,
          border: `1px solid ${colors.white.main} !important`,
          fontWeight: 900,
          letterSpacing: "0.06rem",
          marginTop: "10px !important",
          padding: "6px 12px",
          "&:hover": {
            border: `1px solid rgba(0,0,0,0.01) !important`,
            color: colors.black.main,
            backgroundColor: colors.white.main,
          },
        },
        endIcon: {
          marginLeft: "2px",
          marginRight: "-7px",
        },
        startIcon: {
          marginRight: "2px",
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          margin: "0px -8px",
          color: colors.white.main,
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "white",
          fontWeight: "600 !important",
          letterSpacing: "0.03rem",
          fontSize: "12px",
        },
      },
    },
  },
});

export default theme;
